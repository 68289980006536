import { Component } from '@angular/core';
import { ConnectionService } from 'ng-connection-service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from './auth/services/auth.service';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: []
})
export class AppComponent {
  title = 'gamdaymeal-admin';
  status = 'ONLINE';
  isConnected = true;
  totalTime:any;

  currentUser:any;

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
  }
  constructor(
    private connectionService: ConnectionService,
    private toastrService: ToastrService,
    private authService: AuthService
  ) {
    this.connectionService.monitor().subscribe(isConnected => {
      this.isConnected = isConnected;
      if (this.isConnected) {
        this.status = "ONLINE";
        this.totalTime = 0;
      }
      else {
        this.status = "OFFLINE";
        this.authService.logout(this.currentUser?._id,{});
        this.totalTime = 0;
        let x = setInterval(()=>{  
          this.totalTime++;
          if(this.status == "ONLINE"){
            clearInterval(x);
          }
          if(this.totalTime>=60 && this.status=='OFFLINE'){
            this.toastrService.error('Connection Lost');
            clearInterval(x);
          }
        },1000)
      }
    })
  }
}
