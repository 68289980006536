<div class="sidebar">
  <div class="logo"><img src="../../../assets/TripSqueeLogo.svg" alt="logo"></div>
  <div class="header-links">

    <a routerLink="/dashboard" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/dashboard_black.svg" alt="">
        <img class="white-icon" src="../../../assets/sidbar_icons/dashboard_white.svg" alt="">
    </span>Report</a>

    <a routerLink="/manage-trip" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Manage Category.svg" alt="">
        <img class="white-icon" src="../../../assets/sidbar_icons/Manage Category_White.svg" alt="">
    </span>Manage Trip Types</a>

    <a routerLink="/category" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/category-black.svg" alt="">
        <img class="white-icon" src="../../../assets/sidbar_icons/category-white.svg" alt="">
    </span>Manage Category</a>

    <a  routerLink="/change-password" routerLinkActive="active">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Chnage_Password_Black.svg" alt="">
        <img class="white-icon" src="../../../assets/sidbar_icons/Change_Password_White.svg" alt="">
  </span>Change Password</a>


    <a routerLinkActive="active" (click)="openModal(template)" id="logout">
      <span class="sidebar-icon">
        <img class="black-icon" src="../../../assets/sidbar_icons/Log Out Black.svg" alt="">
        <img class="white-icon" src="../../../assets/sidbar_icons/Log Out Black.svg" alt="">
    </span>Logout</a>
  </div>
</div>

<!-- Logout confirm -->
<ng-template #template>
  <div class="modal-header">
      <button type="button" class="close pull-right" aria-label="Close" (click)="modalRef.hide()">
          <span aria-hidden="true">&times;</span>
      </button>
  </div>
  <div class="modal-body text-center delete-modal align-items-center"> 
      <img src="../../../../../assets/images/create-successfull-img.svg" class="logoutImg" alt="">
      <p>Are you sure you <br>want to logout?</p>
      <div class="modal-btn d-flex justify-content-around logoutBtn">
          <button class="btn btn-cancel" (click)="cancel()">No</button>
          <button class="btn btn-blue" (click)="confirm()" >Yes</button>
      </div>
  </div>
</ng-template>