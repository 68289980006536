<div class="header">
    <div class="left-sec">
        <ng-container *ngIf="welcomeUserMsg">
            <h1>Report</h1>
        </ng-container>
        <h1 *ngIf="dashboardHeading">Report</h1>
        <h1 *ngIf="changePasswordHeading">Change Password</h1>
        <h1 *ngIf="manageVideos">Manage Videos</h1>
        <h1 *ngIf="manageCardsHeading">Manage Cards</h1>
        <h1 *ngIf="manageCardsDetailsHeading">Manage Cards Details</h1>
        <h1 *ngIf="manageCategoryHeading">Manage Trip Types</h1>
        <h1 *ngIf="notificationHeading">Broadcast Notifications</h1>
        <h1 *ngIf="amenitiesHeading">Manage Amenities</h1>
        <h1 *ngIf="categoryHeading">Manage Category</h1>
        
    </div>


    <div class="right-sec">
        <div class="userName">Admin </div>
        <div class="btn-group" dropdown placement="bottom right">
        </div>
    </div>
</div>