import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent {

  submitted: boolean = false;
  constructor(
    private authService: AuthService,
    private router: Router,
    private toastr: ToastrService
  ) { }

  // emailreg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
  emailreg = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[\d{1,3}\.\d{1,3}\.\d{1,3}\.\d{1,3}\])|(([a-zA-Z\-\d]+\.)+[a-zA-Z]{2,}))$/
  resetForm = new FormGroup({
    email: new FormControl('', [Validators.required, Validators.email,Validators.pattern(this.emailreg)])
  })

  get f() {
    return this.resetForm.controls;
  }

  sendEmailToLogin:any;
  resetPassword() {
    this.submitted = true;
    if (this.resetForm.invalid || this.resetForm.pristine) return;
    this.submitted = false;
    localStorage.setItem('email',JSON.stringify(this.resetForm.value.email));
    this.authService.forgetPassword({email:this.resetForm.value.email.toLowerCase()})
    .subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("password reset successfully");
        this.sendEmailToLogin = this.resetForm.value.email.toLowerCase();
        this.router.navigateByUrl('/auth/email-send');
        this.authService.SendToChild1(this.sendEmailToLogin);
        localStorage.setItem('receiveEmail', JSON.stringify(this.sendEmailToLogin));
      }
    },(err)=>{
      this.toastr.error(err?.error?.data?.message);
    })
  }

}
