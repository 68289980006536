import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";
import { Subject } from "rxjs";

@Injectable()

export class AuthService {
    userName: any = 'tripsquee';
    password: any = 'Xy#9Lp$@e67Fq2!S5%mD4R!zN&pUo@x1';


    private emailSend = new Subject<any>();
    child1$ = this.emailSend.asObservable();


    SendToChild1(msg: string) {
        this.emailSend.next(msg);
    }

    url: any = environment.API_URL;

    constructor(
        private http: HttpClient
    ) { }

    public get currentUserValue() {
        let data = localStorage.getItem('currentUser');
        let obj: Object = {};
        if (data !== 'undefined')
            return JSON.parse(localStorage.getItem('currentUser'));
        return false;
    }


    public get basicAuthentication() {
        let data = {
            userName: this.userName,
            password: this.password
        }

        return data;
    }

    public getData(data:any){
        let res:any = '';
        let index = 0;
        for(var i of data){
          if(data.length>1 && index!=data.length-1){
            res = `${res} ${i} , `
          }else{
            res = `${res} ${i}`
          }
          index++
        }
        if(data.length==0){
            return 'N/A'
        }

        res = res.replaceAll(" ,",", ");
        res = res.replaceAll(",",", ");
        res = res.replaceAll(" ,",", ");
        return res
    }

    public showHIde(x,y){
        (<HTMLInputElement>document.getElementById('showMoreData'+x+y)).style.display = 'none';
        (<HTMLInputElement>document.getElementById('showLess'+x+y)).style.display = 'block';
    }

    public validateText(e:any){
        var regex = new RegExp("^[a-zA-Z0-9_]+$");
        var str = String.fromCharCode(!e.charCode ? e.which : e.charCode);
        if (regex.test(str)) {
          return true;
        }
        e.preventDefault();
        return false;
    }

    login(user: any) {
        return this.http.post<any>(this.url + 'user/admin-login', user);
    }


    forgetPassword(data: any) {
        return this.http.patch(this.url + 'user/admin-reset-password', data);
    }

    logout(id: any,data:any) {
        return this.http.patch(this.url + 'user/logout/'+id,data);
    }

    changePassword(data: any) {
        return this.http.patch(this.url + 'user/admin-change-password', data);
    }

    checkPassword(data: any) {
        return this.http.post(this.url + 'admin/checkPassword', data);
    }

}