import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class HeaderService {
  welcomeUserMsg = new BehaviorSubject(false);
  dashboardHeading =  new BehaviorSubject(false);
  changePasswordHeading = new BehaviorSubject(false);
  manageVideos = new BehaviorSubject(false);
  manageCardsHeading = new BehaviorSubject(false);
  manageCardsDetailsHeading = new BehaviorSubject(false);
  manageCategoryHeading = new BehaviorSubject(false);
  notificationHeading = new BehaviorSubject(false);
  amenitiesHeading = new BehaviorSubject(false);
  categoryHeading = new BehaviorSubject(false);
}
