<div id="auth-width">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-6">
        <div class="login-banner">
          <img src="../../../../assets/TripSqueeLogo.svg" alt="login-banner" class="img-fluid">
        </div>
      </div>
      <div class="col-md-6">
        <div class="login-field">
          <div class="login-box">
            <h3>Sign In</h3>
            <form [formGroup]="loginForm" (submit)="login()">
              <div class="form-group">
                <label for="email">Email Address <span class="require">*</span></label>
                <span class="input-icon"><img src="../../../../assets/icons/email.svg" alt=""
                    style="margin-top: -10px;"></span>
                <input type="email" class="form-control" placeholder="Email" id="email" formControlName="email">
                <div *ngIf="submitted && f.email.errors" class="alert alert-danger">
                  <span *ngIf="f.email.errors.required">
                    Email Required
                  </span>
                  <span *ngIf="f.email.errors.email || f.email.errors.pattern">
                    Invalid Email
                  </span>
                </div>
              </div>

              <div class="form-group">
                <label>Password <span class="require">*</span></label>
                <span class="input-icon"><img src="../../../../assets/icons/password.svg" alt=""
                    style="margin-top: -10px" /></span>
                <input [type]="hide ? 'password' : 'text'" class="form-control" placeholder="Password" id="password"
                  formControlName="password" />
                <mat-icon class="passwordIcon" (click)="myFunction()">{{hide ?
                  "visibility_off" : "visibility"
                  }}</mat-icon>

                <div class="alert alert-danger" *ngIf="
                    loginForm.controls.password.hasError('required') &&
                    loginForm.controls.password.touched &&
                    !submitted
                  ">
                  Password Required
                </div>

                <div class="alert alert-danger" *ngIf="
                    loginForm.controls.password.hasError('minlength') &&
                    loginForm.controls.password.touched &&
                    !submitted
                  ">
                  Password must be of six or more characters
                </div>
                <div *ngIf="submitted && f.password.errors" class="alert alert-danger">
                  <span *ngIf="f.password.errors.required">
                    Password Required
                  </span>

                  <span *ngIf="f.password.errors.minlength">
                    Password must be of six or more characters
                  </span>
                </div>

                <div class="forgot-pass mt-2 d-inline-block float-right mb-4" routerLink="/auth/forgot-password">
                  Forgot Password?
                </div>
              </div>

              <button type="submit" class="btn btn-primary">Sign In</button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>