<app-layout></app-layout>
<div class="content">
        <div class="row mt-4">
            <div class="total-counts">
                <div class="total-count total-users col-lg-5 mt-3 card-border" routerLink="/dashboard/total-renters">
                    <img src="../../../assets/dashboard_icons/Devices.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="client" class="counter">{{dashboardAllData?.rentersCount ? dashboardAllData?.rentersCount : 1000}}</h2>
                        <p>Total Number of Registered Customers </p>
                    </div>
                </div>

                <div class="total-count total-careers  col-lg-5 mt-3 card-border" routerLink="/dashboard/total-leaders">
                    <img src="../../../assets/dashboard_icons/Active users.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="caregiver" class="counter">{{dashboardAllData?.lendersCount ? dashboardAllData?.lendersCount : '$123900'}}</h2>
                        <p>Total Revenue Generated</p>
                    </div>
                </div>

                <div class="total-count total-gs  col-lg-5 mt-3 card-border" routerLink="/dashboard/product-request">
                    <img src="../../../assets/dashboard_icons/Active age group.svg" alt="" height="60" width="60">
                    <div>
                        <h2 id="booking">{{dashboardAllData?.requestedProductCount ? dashboardAllData?.requestedProductCount : 30}}</h2>
                        <p>Total Number of Sites </p>
                    </div>
                </div>
            </div>
        </div>
</div>