import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageTripService {

    url: any = environment.API_URL;


    constructor(
        private http: HttpClient
    ) {

    }

    getTripsList() {
        return this.http.get(this.url + 'category/get-trip-types');
    }

    addTrip(data: any) {
        return this.http.post(this.url + 'category/create-trip-type', data);
    }

    updateTrip(data: any) {
        return this.http.patch(this.url + 'category/update-trip-type', data);
    }

    deleteTrip(id: any) {
        return this.http.delete(this.url + 'category/delete-trip-type/'+id);
    }

    uploadImage(data: any) {
        return this.http.post(this.url + 'upload', data);
    }
    
}