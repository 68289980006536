import { Injectable } from '@angular/core';
import { registerLocaleData } from '@angular/common';
import localeFrench from '@angular/common/locales/fr';

@Injectable({ providedIn: 'root' })
export class SessionService {

    private _locale: string;

    set locale(value: string) {
        this._locale = value;
    }
    get locale(): string {
        return this._locale || 'en-US';
    }

    registerCulture(culture: string) {
        if (!culture) {
            return;
        }
        this.locale = culture;

        // Register locale data since only the en-US locale data comes with Angular
        if(culture=='fr-CA'){
            registerLocaleData(localeFrench);
        }
    }
}