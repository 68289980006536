import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "../../../environments/environment";

@Injectable()
export class ManageCategoriesService {

    url: any = environment.API_URL;
    constructor(
        private http: HttpClient
    ) {

    }

    getCategory() {
        return this.http.get(this.url + 'category/get-categories');
    }

    addCategory(data: any) {
        return this.http.post(this.url + 'category/create-category', data);
    }

    uploadImage(data: any) {
        return this.http.post(this.url + 'upload', data);
    }

    updateCategory(data: any) {
        return this.http.patch(this.url + 'category/update-category', data);
    }

    deleteCategory(id: any) {
        return this.http.delete(this.url + 'category/delete-category/'+id);
    }
    
}