import { Component, OnDestroy, OnInit } from '@angular/core';
import { HeaderService } from 'src/app/shared/services/header.service';
import { DashboardService } from '../services/dashboard.service';
import { AuthService } from 'src/app/auth/services/auth.service';
@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  dashboardAllData:any;
  constructor(
    private _header: HeaderService, 
    private dashboardService: DashboardService,
    private authService: AuthService,
    ) { }

  ngOnInit(): void {
    this._header.welcomeUserMsg.next(true);
  }
  

  ngOnDestroy() {
    this._header.welcomeUserMsg.next(false);
  }

  getUsersCount(){
    this.dashboardService.getDashboard().subscribe((res:any)=>{
      if(res.success){
          this.dashboardAllData = res.output[0]
      }
    })
  }
}
