import { Component, OnInit } from '@angular/core';
import { AuthService } from '../../services/auth.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-email-send',
  templateUrl: './email-send.component.html',
  styleUrls: ['./email-send.component.scss']
})
export class EmailSendComponent implements OnInit {

  constructor(
    private authService: AuthService,
    private toast: ToastrService
  ) { }

  email:any;
  ngOnInit(): void {
    this.email = JSON.parse(localStorage.getItem('email'));
  }

  resend(){
    this.authService.forgetPassword({email:this.email.toLowerCase()})
    .subscribe((res: any) => {
      if (res.success) {
        this.toast.success("password reset successfully");
      }
    })
  }

}
