import { Component, OnInit, TemplateRef } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from 'src/app/auth/services/auth.service';
import { BsModalService } from "ngx-bootstrap/modal";
import { ActivatedRoute, Router } from "@angular/router";

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
})
export class SidebarComponent implements OnInit {
  constructor(
    private toAsterService: ToastrService,
    private authService: AuthService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
  ) { }

  modalRef: any;
  currentUser: any;

  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
  }

  ngDoCheck() {

    if (window.location.pathname.indexOf('coupon') != -1) {
      document.getElementById('couponmenu').scrollIntoView();
    } else if (window.location.pathname.indexOf('coordinates') != -1) {
      document.getElementById('Coordinates').scrollIntoView();
    } else if (window.location.pathname.indexOf('notifications') != -1) {
      document.getElementById('notifications').scrollIntoView();
    } else if (window.location.pathname.indexOf('event-sponsors') != -1) {
      document.getElementById('Sponsors').scrollIntoView();
    } else if (window.location.pathname.indexOf('treasure') != -1) {
      document.getElementById('Treasure').scrollIntoView();
    } else if (window.location.pathname.indexOf('more-sections') != -1) {
      document.getElementById('More-Section').scrollIntoView();
    } else if (window.location.pathname.indexOf('home-partners') != -1) {
      document.getElementById('home-partners').scrollIntoView();
    }


  }


  logout() {
    this.authService.logout(this.currentUser._id,{}).subscribe((res: any) => {
      if (res.success) {
        this.modalRef.hide();
        this.toAsterService.success(res.data.message);
        this.router.navigateByUrl("/auth/login");
        localStorage.removeItem("currentUser");
      }
    },(err)=>{
      this.toAsterService.error(err.error.message);
    });
  }

  // modal lougout confirmation
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template);
  }

  confirm() {
    this.logout();
  }

  cancel() {
    this.modalRef.hide();
  }
}
