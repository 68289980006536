import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { BsModalService } from "ngx-bootstrap/modal";
import { HeaderService } from "../services/header.service";
import { AuthService } from "src/app/auth/services/auth.service";
import { ToastrService } from "ngx-toastr";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  welcomeUserMsg: boolean = false;
  dashboardHeading:boolean = false;
  changePasswordHeading:boolean = false;
  manageVideos:boolean = false;
  manageCardsHeading:boolean = false;
  manageCardsDetailsHeading:boolean = false;
  manageCategoryHeading:boolean = false;
  notificationHeading: boolean = false;
  amenitiesHeading: boolean = false;
  categoryHeading: boolean = false;
  default: boolean;
  private returnUrl: any;
  constructor(
    private _header: HeaderService,
    public router: Router,
    private modalService: BsModalService,
    private route: ActivatedRoute,
    private authService: AuthService,
    private toAsterService: ToastrService
  ) {

    this._header.welcomeUserMsg.subscribe((res) => {
      this.welcomeUserMsg = res;
    });


    this._header.dashboardHeading.subscribe((res) => {
      this.dashboardHeading = res;
    });


    this._header.changePasswordHeading.subscribe((res) => {
      this.changePasswordHeading = res;
    });

    this._header.manageVideos.subscribe((res) => {
      this.manageVideos = res;
    });    

    this._header.manageCardsHeading.subscribe((res) => {
      this.manageCardsHeading = res;
    });    

    this._header.manageCategoryHeading.subscribe((res) => {
      this.manageCategoryHeading = res;
    });    

    this._header.manageCardsDetailsHeading.subscribe((res) => {
      this.manageCardsDetailsHeading = res;
    });    

    this._header.notificationHeading.subscribe((res) => {
      this.notificationHeading = res;
    });  

    this._header.amenitiesHeading.subscribe((res) => {
      this.amenitiesHeading = res;
    }); 

    this._header.categoryHeading.subscribe((res) => {
      this.categoryHeading = res;
    }); 

  }

  userName: any;
  firstName:any;
  lastName: any;
  allDate:any;


  currentUser:any;
  ngOnInit(): void {
    this.currentUser = this.authService.currentUserValue;
    this.userName = "Admin";
    this.currentUser = this.authService.currentUserValue;
    this.allDate = this.authService.currentUserValue;
    this.userName = this.allDate?.firstName?.toUpperCase();
  }
}
